const Routes = {
  home: '/',
  login: '/login',
  chooseAccount: '/choose-account',
  influencerRegistration: '/influencers/signup',
  companyRegistration: '/companies/signup',
  plans: {
    discovery: '/discovery-plans',
  },
  discoverInfluencers: '/discover-influencers',
  companyProfile: {
    show: '/company/profile',
    editProfile: '/company/profile/edit',
    subscriptionDetails: '/company/profile/subscription',
    transactions: '/company/profile/transactions',
  },
  influencers: {
    influencerProfile: '/influencers/:id/profile',
    myInfluencers: '/influencers',
  },
  irmLists: {
    index: '/lists',
    show: '/lists/:id',
  },
  campaigns: {
    index: '/campaigns',
    show: '/campaigns/:id',
    influencersStatistics: '/campaigns/:id/influencers/statistics',
  },
};

export default Routes;
