import { createContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

const PaginationMapperContext = createContext({
  fetchKeys: {},
  fetchPolicies: {},
  dispatch: () => {},
});

const EVENTS = {
  CAMPAIGN_CREATED: Symbol('Campaign Created'),
  IRM_LIST_DELETED: Symbol('Irm List Deleted'),
  IRM_LIST_CREATED: Symbol('Irm List Created'),
  IRM_LIST_UPDATED: Symbol('Irm List Updated'),
  COMPANY_INFLUENCER_CREATED: Symbol('Company Influencer Created'),
  IRM_LIST_COMPANY_INFLUENCER_DELETED: Symbol('Company Influencer Deleted from an IRM List'),
  INFLUENCER_TASK_DELETED: Symbol('Influencer Task Deleted'),
  CAMPAIGN_INFLUENCER_UPDATED: Symbol('Campaign Influencer Updated'),
  IRM_LIST_ADDED_TO_CAMPAIGN: Symbol('Irm List Added to Campaign'),
};

const AFFECTED_FIELDS = {
  [EVENTS.CAMPAIGN_CREATED]: ['campaigns'],
  [EVENTS.IRM_LIST_DELETED]: ['irmLists'],
  [EVENTS.IRM_LIST_CREATED]: ['irmLists'],
  [EVENTS.IRM_LIST_UPDATED]: ['irmLists'],
  [EVENTS.COMPANY_INFLUENCER_CREATED]: ['companyInfluencers'],
  [EVENTS.IRM_LIST_COMPANY_INFLUENCER_DELETED]: ['companyInfluencers', 'irmLists'],
  [EVENTS.INFLUENCER_TASK_DELETED]: ['influencerTasks'],
  [EVENTS.CAMPAIGN_INFLUENCER_UPDATED]: ['companyInfluencers'],
  [EVENTS.IRM_LIST_ADDED_TO_CAMPAIGN]: ['companyInfluencers'],
};

const INITIAL_STATE = {
  fetchPolicies: {},
  fetchKeys: {},
};

const NETWORK_ONLY = 'network-only';

const reducer = (state, action) => {
  const fields = AFFECTED_FIELDS[action.type];
  if (!fields) throw new Error(`Invalid action: ${action.type}`);

  const newFetchKeys = { ...state.fetchKeys };
  const newFetchPolicies = { ...state.fetchPolicies };

  fields.forEach((fieldName) => {
    newFetchKeys[fieldName] = (newFetchKeys[fieldName] || 0) + 1;
    newFetchPolicies[fieldName] = NETWORK_ONLY;
  });

  return {
    ...state,
    fetchKeys: newFetchKeys,
    fetchPolicies: newFetchPolicies,
  };
};

const PaginationMapperContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const value = useMemo(() => ({
    fetchKeys: state.fetchKeys,
    fetchPolicies: state.fetchPolicies,
    dispatch,
  }), [state, dispatch]);

  return (
    <PaginationMapperContext.Provider value={value}>
      {children}
    </PaginationMapperContext.Provider>
  );
};

PaginationMapperContextProvider.propTypes = {
  children: PropTypes.node,
};

PaginationMapperContextProvider.defaultProps = {
  children: null,
};

export default PaginationMapperContext;

export {
  PaginationMapperContextProvider,
  EVENTS,
};
